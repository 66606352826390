import * as React from "react";
import {seoData} from "../data/seo";
import {introData} from "../data/intro";
import {navData} from "../data/nav";
import {textData} from "../data/text";
import {accordionData, accordionLabel} from "../data/accordion";

import Seo from "../components/seo";
import IntroVideo from "../components/intro-video";
import Image from "../components/image";
import ContentNextPage from "../components/content-next-page";
import Footer from "../components/footer";
import Composition from "../components/composition";
import ContentText from "../components/content-text";
import ContentAccordion from "../components/content-accordion";
import ContentImageText from "../components/content-img-text";
import ContentDiscover from "../components/content-discover";
import {discoverData} from "../data/discover";
import useWindowSize from "../helpers/UseWindowSize";
import NewComposition from "../components/composition/test";
import firstCompLeft from "../assets/images/beach-tower/beach-tower-compos_1_left.png";
import firstCompRight from "../assets/images/beach-tower/beach-tower-compos_1_right.png";
import secondCompRight from "../assets/images/beach-tower/beach-tower-compos_2_right.png";
import secondCompRightMob from "../assets/images/beach-tower/beach-tower-compos_2_1_right.png";
import {CompositionWrapper} from "../components/composition/test/styled.index";
import leftFooter from "../assets/images/compos/beach_compos_footer_left.png";
import rightFooter from "../assets/images/compos/beach_compos_footer_right.png";
import leftFooterM from "../assets/images/compos/beach-compos_mob_0_left.png";
import rightFooterM from "../assets/images/compos/beach-compos_mob_0_right.png";

const BeachTowerPage = () => {
  const {width} = useWindowSize();
  if (typeof window === `undefined`) {
    return(<></>);
  }
  return (
    <main>
      <Seo page={seoData.beachTower}/>
      <IntroVideo page={introData.beachTower}/>

      <CompositionWrapper>
        <ContentText
          bgClassName={width >= 600 ? "bg" : "bg high lp"}
          heading={textData.beachTower1.heading}
          text={textData.beachTower1.text}
          type={'narrow'}
        />

        {width >= 1024 &&
            <NewComposition imgSrc={firstCompLeft} bottom={"5%"} left={"-260px"} height={'95%'}/>}
        {width >= 1024 &&
            <NewComposition imgSrc={firstCompRight} top={"5%"} right={"-280px"} height={'90%'}/>}
        {width < 568 &&
            <Composition imgName={"beach-tower-compos_mob_1.png"} align={"offTop2"}/>}
        {width < 568 &&
            <Composition imgName={"beach-tower-compos_mob_1_1.png"} align={"offBottom4"}/>}
      </CompositionWrapper>
      <Image
        imageName={"beach-tower_1.jpg"}
        altText={"The Beach Tower"}
        padding={width >= 1885 ? "0 9.3% 9.3%" : "0 85px 9.3%"}
        paddingMob={"0 5.2% 5.2%"}
        move={false}
      />

      <ContentDiscover
        img={discoverData.tower3}
        imgHR={discoverData.tower3hr}
        imgMob={discoverData.tower3Mob}
        labels={false}
        pX={2}
        pY={1.4}
        zLevel={2.1}
      />
      <CompositionWrapper>
        <ContentAccordion
          label={accordionLabel.beachTowerAmenities.label}
          accData={accordionData.beachAmenities}
        />
        {width >= 1024 &&
            <NewComposition shape={"circle"} top={"200px"} right={"93vw"} height={'600px'}/>}
        {width >= 1270 &&
            <NewComposition imgSrc={secondCompRight} top={"0"} right={"-230px"} height={'90vh'}/>}
        {width >= 1024 && width < 1270 &&
            <NewComposition imgSrc={secondCompRightMob} top={"0"} right={"0"} height={'90vh'}/>}
        {width < 568 && <Composition imgName={"beach-tower-compos_3_mob.png"} align={"top"}/>}
      </CompositionWrapper>
      <Image
        imageName={"beach-tower_3.jpg"}
        altText={"The Beach Tower"}
        padding={width >= 1885 ? "0 9.3%" : "0 85px"}
        paddingMob={"0 5.2%"}
        move={false}
      />
      <Image
        imageName={"beach-tower_4.jpg"}
        altText={"The Beach Tower"}
        padding={"9.3% 0 0"}
        paddingMob={"5.2% 0 0"}
        move={true}
      />
      <ContentImageText
        type={"row lpt"}
        imgName={"beach-tower_5.jpg"}
        heading={textData.beachTower2.heading}
        text={textData.beachTower2.text}
      />
      <Image imageName={"beach-tower_6.jpg"} altText={"The Beach Tower"} move={true}/>
      <ContentImageText
        type={"row-rev t2 lpt lls"}
        imgName={"beach-tower_7.jpg"}
        text={textData.beachTower3.text}
      />

      <Image imageName={"beach-tower_8.jpg"} altText={"The Beach Tower"} move={true}/>
      <Image
        imageName={"beach-tower_9.jpg"}
        altText={"The Beach Tower"}
        padding={width >= 1885 ? "9.3%" : "9.3% 85px"}
        paddingMob={"5.2%"}
        move={false}
      />
      <Image
        imageName={"beach-tower_010.jpg"}
        altText={"The Beach Tower"}
        padding={"0 0 9.3%"}
        paddingMob={"0 0 5.2%"}
        move={true}
      />
      <CompositionWrapper>
        <section>
          <ContentNextPage
            nextLink={navData.beachTowerRes.link}
            nextTitle={navData.beachTowerRes.next}
            mobHeight={"420px"}
            textAlign={'center'}
            vertAlign={"center"}
          />
          {width < 600 && <NewComposition imgSrc={leftFooterM} bottom={width > 380 ? "-25px": "-30px"} left={width > 380 ? "0" : "-10px"} height={width > 400 ? '100vw' : '90vw'}/>}
          {width < 600 && <NewComposition imgSrc={rightFooterM} bottom={width > 380 ? "-25px": "-30px"} right={width > 380 ? "0" : "-10px"} height={width > 400 ? '100vw' : '90vw'}/>}
        </section>
        <Footer/>

        {width >= 1024 && <NewComposition imgSrc={leftFooter} bottom={"0"} left={width >= 1250? "0" : "-4%"} height={width> 1200 ? "88vh" : '80vh'}/>}
        {width >= 1024 && <NewComposition imgSrc={rightFooter} bottom={"0"} right={"0"} height={width> 1200 ? "88vh" : '80vh'}/>}
      </CompositionWrapper>
    </main>
  );
};

export default BeachTowerPage;